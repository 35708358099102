import { Container, styled, Typography } from '@mui/material';

/*
 * This is a prototype component that we use to link in an uneditable
 * component from contentful. Note that the name is used for linking,
 * so it cannot be renamed.
 */
interface LegalTextProps {
  'padding-style': string;
}

// const StyledContainer = styled(Container)(() => ({
//   marginTop: '16px',
//   marginBottom: '24px',
// }));

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isHomePadding',
})<{ isHomePadding: boolean }>(({ isHomePadding }) => ({
  marginTop: '16px',
  marginBottom: '24px',
  '@media (min-width: 1200px)': {
    paddingLeft: isHomePadding ? '0px !important' : 'auto',
  },
}));

export const LegalTextLoanRangesGoogle = (props: LegalTextProps) => {
  const isHomePadding = props['padding-style'] === 'home';

  return (
    <StyledContainer maxWidth="lg" isHomePadding={isHomePadding}>
      <Typography variant="h5">Ratenkredit Konditionen Übersicht</Typography>
      <Typography variant="body1" sx={{ fontSize: '12px', lineHeight: '20px' }}>
        <b>Zinssätze:</b> Min. -0,40 % bis Max. 19,90 % effektiver Jahreszins
        <br />
        <b>Laufzeit:</b> Min. 6 bis Max. 120 Monate
        <br />
        <b>Nettodarlehensbetrag</b> von 1.000 € bis 150.000 €<br />
        <b>Gesamtbetrag</b> von 995,83 € bis 181.247,51 €
      </Typography>
    </StyledContainer>
  );
};
