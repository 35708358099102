import dynamic from 'next/dynamic';

import type { ICePrototypeFields } from 'types/generated/contentful';
export type PrototypeProps = ICePrototypeFields;

import type { PrototypeExampleProps } from './PrototypeExample/PrototypeExample';

// Components that we always want to have ready to render
// are declared here and not dynamically imported.
import { UnknownComponent } from '../UnkownComponent';
import { BankingPartnersBanner } from './BankingPartnersBanner/BankingPartnersBanner';
import { LegalTextLoanRangesGoogle } from './LegalTextLoanRangesGoogle/LegalTextLoanRangesGoogle';

const componentMap = {
  // dynamic import for components that are not always needed
  PrototypeExample: dynamic<PrototypeExampleProps>(() =>
    import(
      'components/ContentfulContent/Prototypes/PrototypeExample/PrototypeExample'
    ).then((module) => module.PrototypeExample),
  ),
  // direct import for components that are always needed
  BankingPartnersBanner: BankingPartnersBanner,
  LegalTextLoanRangesGoogle: LegalTextLoanRangesGoogle,
};

export const Prototype = (props: PrototypeProps) => {
  const { config } = props;

  const { component: componentName } = config.data;
  if (!componentName) return null;
  // @ts-ignore
  const Component = componentMap[componentName];
  if (!Component) return <UnknownComponent name={componentName} />;
  // @ts-ignore
  return <Component {...config.props} />;
};
